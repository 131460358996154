import { Environment } from '../../../environment'

import axios from 'axios'
import { errorInterceptor, responseInterceptor } from './interceptors'
import { clearPersistedStorage } from './interceptors/handlers'

const Api = axios.create({
  baseURL: Environment.URL_BASE,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

const ExternalApi = axios.create({
  baseURL: Environment.URL_BASE,
  withCredentials: false,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

Api.interceptors.response.use(
  (response) => responseInterceptor(response),
  async (error) => {
    const isAuthenticationError = [401, 403].includes(
      Number(error?.response?.status),
    )

    if (isAuthenticationError) {
      clearPersistedStorage()
      window.location.href = '/admin'
    } else {
      return errorInterceptor(error)
    }
  },
)

ExternalApi.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error),
)

export interface TotalCount<T> {
  next: string
  count: number
  previous: string
  data: T[]
  results: T[]
}

export { Api, ExternalApi }
