/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography, useTheme } from '@mui/material'

export function FormatProtocolStatus(
  value: number,
  table = true,
  height?: any,
  borderRadius?: string,
  fontSize?: any,
) {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      borderRadius={borderRadius || '15px'}
      height={table ? theme.spacing(3) : height}
      justifyContent="center"
      alignItems="center"
      color="black"
      bgcolor={
        value === 1
          ? '#EB3324'
          : value === 2
          ? '#FFFF00'
          : value === 3
          ? '#CBAACB'
          : value === 4
          ? '#55CBCD'
          : value === 5
          ? '#8bdaa7'
          : '#999999'
      }
    >
      <Typography
        variant="subtitle2"
        component="h6"
        color="black"
        fontSize={fontSize || 14}
      >
        {value === 0
          ? 'Recurso'
          : value === 1
          ? 'Atrasado'
          : value === 2
          ? 'Pendente'
          : value === 3
          ? 'Trâmite'
          : value === 4
          ? 'Prorrogado'
          : value === 5
          ? 'Concluído'
          : 'Indeferido'}
      </Typography>
    </Box>
  )
}

// (1, 'Atrasado'),
// (2, 'Pendente'),
// (3, 'Trâmite'),
// (4, 'Prorrogado'),
// (5, 'Concluído'),
// (6, 'Indeferido')
