/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo } from 'react'
import usePersistedState from '../hooks/usePersistedState'
import { localStorageConstants } from '../constants'

export interface IModule {
  id: number
  type: 'ombudsman' | 'ci' | 'customer-service'
  title: 'string'
  profile: number
  hr_manager: boolean
  logo: string
  deadline: number
}

export interface UserData {
  modules: Array<IModule>
  institution_id: number
  profile_picture?: string
  username: string
  gender: number
  id: number
}

interface IAuthContextData {
  logout(navigate: boolean): void
  setUser(data: UserData): void
  user: UserData | undefined
  isAuthenticated: boolean
}

const AuthContext = createContext({} as IAuthContextData)

interface IAuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [userId, setUserId] = usePersistedState<number | undefined>(
    localStorageConstants.LOCAL_STORAGE_KEY__ID,
    undefined,
    true,
  )
  const [userModules, setUserModules] = usePersistedState<Array<IModule>>(
    localStorageConstants.LOCAL_STORAGE_KEY__MODULES,
    [],
    true,
  )
  const [institutionId, setinstitutionId] = usePersistedState<
    number | undefined
  >(localStorageConstants.LOCAL_STORAGE_KEY__INSTITUTION_ID, undefined, true)
  const [profilePicture, setProfilePicture] = usePersistedState<
    string | undefined
  >(localStorageConstants.LOCAL_STORAGE_KEY__PROFILE_PICTURE, undefined, true)
  const [username, setUsername] = usePersistedState<string>(
    localStorageConstants.LOCAL_STORAGE_KEY__USERNAME,
    '',
    false,
  )
  const [gender, setGender] = usePersistedState<number | undefined>(
    localStorageConstants.LOCAL_STORAGE_KEY__GENDER,
    undefined,
    true,
  )

  const user = useMemo(() => {
    let data: UserData | undefined
    if (gender && institutionId && userModules && userId) {
      data = {
        id: userId,
        gender,
        username,
        modules: userModules,
        institution_id: institutionId,
        profile_picture: profilePicture,
      }
    } else {
      data = undefined
    }
    return data
  }, [gender, institutionId, userModules, userId, username, profilePicture])

  function logout(navigate = false) {
    setUsername('')
    setUserModules([])
    setUserId(undefined)
    setGender(undefined)
    setinstitutionId(undefined)
    setProfilePicture(undefined)

    localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__ID)
    localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__GENDER)
    localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__MODULES)
    localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__USERNAME)
    localStorage.removeItem(
      localStorageConstants.LOCAL_STORAGE_KEY__INSTITUTION_ID,
    )
    localStorage.removeItem(
      localStorageConstants.LOCAL_STORAGE_KEY__PROFILE_PICTURE,
    )
    localStorage.removeItem('ADM_SELECTED_INSTITUTION')

    if (navigate) {
      window.location.href = '/admin'
    }
  }

  function setUser(props: UserData) {
    setUserId(props.id)
    setGender(props.gender)
    setUsername(props.username)
    setUserModules(props.modules)
    setinstitutionId(props.institution_id)
    setProfilePicture(props.profile_picture)
  }

  const isAuthenticated = useMemo(() => {
    return !!user
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        logout,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
