import { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, Grid } from '@mui/material'
import { publicIpv4 } from 'public-ip'
import { toast } from 'react-toastify'

import { VForm, VTextField } from '../../shared/forms'
import { NotificacaoError } from '../../shared/components'
import logo from '../../images/default_logo.png'
import { Api } from '../../shared/services/api/axios-config'
import { useAuthContext } from '../../shared/contexts'
import Login from '@mui/icons-material/Login'
import { useNavigate } from 'react-router-dom'

export const LoginPage = () => {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [publicIp, setPublicIp] = useState<string>('')
  const { isAuthenticated, logout, setUser } = useAuthContext()

  useEffect(() => {
    publicIpv4()
      .then((result) => {
        setPublicIp(result)
      })
      .catch(() =>
        toast.warning(
          'Atenção! Não foi possível identificar o IP Externo que você está conectado',
        ),
      )
  }, [isAuthenticated])

  const handleSubmit = async (loginData: {
    email: string
    password: string
  }) => {
    setLoading(true)
    const data = { ...loginData, client_ip: publicIp }
    try {
      await Api.post('/V1/api/token/', data, {
        withCredentials: true,
      })

      const { data: userData } = await Api.get('/V1/user/profile/', {
        withCredentials: true,
      })

      setUser({
        id: userData.id,
        gender: userData.gender,
        institution_id: userData.institution_id,
        modules: userData.profiles,
        username: userData.username,
        profile_picture: userData.profile_picture,
      })

      setLoading(false)

      if (userData.profiles?.length > 0) {
        navigate(
          `/${userData.profiles[0]?.type}/${userData.profiles[0]?.title}/dashboard`,
        )
      } else {
        NotificacaoError('Sem acesso aos módulos')
        logout(window.location.pathname !== '/admin')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false)
      NotificacaoError((error as { message: string }).message)
      console.error(error)
    }
  }

  return (
    <Box
      display="flex"
      sx={{ background: 'linear-gradient(to right bottom, #430089, #82ffa1)' }}
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      overflow="hidden"
      flexDirection="column"
    >
      <Card sx={{ borderRadius: '20px' }}>
        <CardContent>
          <Box display="flex" flexDirection="column" gap={2} width={300}>
            <img src={logo} alt="TickDoc_Logo" />
            <VForm onSubmit={handleSubmit}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                spacing={1}
                paddingX={2}
              >
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="email"
                    type="email"
                    label="Email"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VTextField
                    fullWidth
                    required
                    size="small"
                    name="password"
                    type="password"
                    label="senha"
                    borderTopLeft="50px"
                    borderBottomLeft="50px"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    disableElevation
                    sx={{ borderRadius: '50px' }}
                  >
                    <Login />
                    Entrar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      window.open('/reset_password', '_blank', 'noopener')
                    }
                  >
                    Esqueci a Senha
                  </Button>
                </Grid>
              </Grid>
            </VForm>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
