/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { IGroupings } from '../agrupamentos-service/AgrupamentosService'
import { ISectors } from '../setores-services/SetoresServices'
import { ISubjects } from '../assuntos-service/AssuntosServices'
import { ITypologies } from '../tipologias-services/TipologiasServices'
import { Api } from '../axios-config'
import { IExternalUsers } from '../usuario-externo-services/UsuarioExternoServices'
import axios from 'axios'

export interface IAddress {
  'ISO3166-2-lvl4': string
  state_district: string
  country_code: string
  municipality: string
  postcode: string
  country: string
  suburb: string
  region: string
  state: string
  city: string
}

export interface IReverseSearch {
  address: IAddress
}

export interface IProtocols {
  id: number
  ip?: string
  file1?: any
  file2?: any
  file3?: any
  file4?: any
  file5?: any
  status?: number
  city_id?: number
  secret?: boolean
  address?: string
  deadline?: number
  priority?: number
  state_id?: number
  latitude?: string
  replies?: number[]
  longitude?: string
  identifier: string
  anonymous?: boolean
  updated_at?: string
  created_at?: string
  expires_at?: string
  view_count?: number
  description: string
  external_ip?: string
  finished_at?: string
  sector_id?: ISectors
  delayed_days?: number
  pending_days?: number
  extended_days?: number
  days_on_appeal?: number
  finished_days: number
  kind_response?: number
  subject_id?: ISubjects
  replied_survey: boolean
  analyzing_days?: number
  institution_id?: number
  grouping_id?: IGroupings
  category_id: ITypologies
  notified_sector?: boolean
  analysis_started?: boolean
  relative_protocol?: string
  anonymous_password?: string
  internal_users_ids?: number[]
  responsible_ombudsman?: number
  justify_resource?: string
  external_user_id?: IExternalUsers
}

export interface IProtocolsList {
  id: number
  status: number
  deadline: number
  priority?: number
  identifier: string
  sector_id: ISectors
  subject_id: ISubjects
  grouping_id: IGroupings
  category_id: ITypologies
  notified_sector?: boolean
}

export type IProtocolsTotalCount = {
  data: IProtocolsList[]
  count: number
  next: string
  previous: string
  results: IProtocolsList[]
}

interface IDashboard {
  total: number
  delayed: number
  pending: number
  extended: number
  priority: number
  rejected: number
  analyzing: number
  completed: number
  urgent_data: IProtocolsList[]
  delayed_data: IProtocolsList[]
  extended_data: IProtocolsList[]
}

const getAllProtocols = async (
  item = '0',
  total = '10',
  filter = '',
  status = '',
  priority = '',
  sectorId = '',
  subjectId = '',
  categoryId = '',
  responsibleOmbudsman = '',
  externalId = '',
): Promise<IProtocolsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/protocols/?item=${item}&total=${total}&search=${filter}&status=${status}&priority=${priority}&sector_id=${sectorId}&subject_id=${subjectId}&category_id=${categoryId}&responsible_ombudsman=${responsibleOmbudsman}&external_user_id=${externalId}`

    const { data } = await Api.get<IProtocolsTotalCount>(relativeUrl)

    if (data) return data

    return Error('Erro ao Listar Registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const getProtocolById = async (id = ''): Promise<IProtocols | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${id}/`

    const { data } = await Api.get<IProtocols>(relativeUrl)

    if (data) return data

    return Error('Erro ao consultar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const updateProtocolById = async (
  id = '',
  formData = {},
): Promise<number | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${id}/`

    const { data } = await Api.patch<IProtocols>(relativeUrl, formData)

    if (data) return data.id

    return Error('Erro ao atualizar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const createProtocol = async (
  formData: {},
  token?: string,
): Promise<IProtocols | Error> => {
  try {
    const relativeUrl = '/V1/protocols/'

    const headers = token ? { Authorization: `Token ${token}` } : {}

    const { data } = await Api.post<IProtocols>(relativeUrl, formData, {
      headers,
    })

    if (data) return data

    return Error('Erro ao criar registro')
  } catch (error) {
    console.error(error)
    return Error(
      (error as { message: string }).message || 'Erro ao criar registro',
    )
  }
}

const resourseProtocolById = async (
  id: number,
  justifyResource: string,
  token?: string,
): Promise<IProtocols | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${id}/resource/`
    const headers = token ? { Authorization: `Token ${token}` } : {}
    const { data } = await Api.patch<IProtocols>(
      relativeUrl,
      { justify_resource: justifyResource },
      {
        headers,
      },
    )

    if (data) return data

    return Error('Erro ao solicitar recurso')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getDashboardData = async (): Promise<IDashboard | Error> => {
  try {
    const relativeUrl = '/V1/dashboard/'

    const { data } = await Api.get(relativeUrl)

    if (data) return data

    return new Error('Erro ao carregar dados da dashboard')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const downloadPdf = async (
  protocolId: number,
  secret: 1 | 0,
  replies: 'all' | 'external' | 'null',
): Promise<Blob | Error> => {
  try {
    const relativeUrl = `/V1/protcols/${protocolId}/view_pdf/${secret}/${replies}/`

    const { data } = await Api.get(relativeUrl, {
      responseType: 'blob',
    })

    if (data) {
      const blob = new Blob([data], { type: 'application/pdf' })
      return blob
    }

    return new Error('Erro ao carregar PDF')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const extendProtocol = async (
  protocolId: number,
  formData: { protocol_id: number; justify: string },
): Promise<string | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${protocolId}/extend/`

    const { data } = await Api.post<{ detail: string }>(relativeUrl, formData)

    if (data) return data.detail

    return new Error('Erro ao prorrogar manifestação')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const viewAnonymous = async (formData: {}): Promise<IProtocols | Error> => {
  try {
    const relativeUrl = `/V1/anonymous/view/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao consultar registro')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const reverseSearchAddress = async (
  lat: number,
  lng: number,
): Promise<IAddress | Error> => {
  try {
    const relativeUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`

    const { data } = await axios.get<IReverseSearch>(relativeUrl)

    if (data) return data.address

    return new Error('Erro ao verificar endereço')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

const getExternalUserProtocols = async (
  item = '0',
  total = '10',
  externalId = '',
): Promise<IProtocolsTotalCount | Error> => {
  try {
    const relativeUrl = `/V1/protocols/?item=${item}&total=${total}&external_user_id=${externalId}`

    const { data } = await Api.get<IProtocolsTotalCount>(relativeUrl)

    if (data) return data

    return Error('Erro ao Listar Registros')
  } catch (error) {
    console.error(error)
    return new Error(
      (error as { message: string }).message || 'Erro ao listar registros',
    )
  }
}

const changeTypology = async (
  protocolId: number,
  formData: {},
): Promise<{ success: string } | Error> => {
  try {
    const relativeUrl = `/V1/protocols/${protocolId}/change_typology/`

    const { data } = await Api.post(relativeUrl, formData)

    if (data) return data

    return new Error('Erro ao alterar tipologia')
  } catch (error) {
    console.error(error)
    return new Error((error as { message: string }).message)
  }
}

export {
  resourseProtocolById,
  downloadPdf,
  viewAnonymous,
  changeTypology,
  createProtocol,
  extendProtocol,
  getAllProtocols,
  getProtocolById,
  getDashboardData,
  updateProtocolById,
  reverseSearchAddress,
  getExternalUserProtocols,
}
