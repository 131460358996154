import { localStorageConstants } from '../../../../constants'

export function clearPersistedStorage() {
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__ID)
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__GENDER)
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__MODULES)
  localStorage.removeItem(localStorageConstants.LOCAL_STORAGE_KEY__USERNAME)
  localStorage.removeItem(
    localStorageConstants.LOCAL_STORAGE_KEY__INSTITUTION_ID,
  )
  localStorage.removeItem(
    localStorageConstants.LOCAL_STORAGE_KEY__PROFILE_PICTURE,
  )
  localStorage.removeItem('ADM_SELECTED_INSTITUTION')
}
