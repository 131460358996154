import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useState } from 'react'
import {
  FormBoxStyle,
  ModalHeader,
  NotificacaoError,
  NotificacaoSucesso,
} from '../../../shared/components'
import { VForm, VTextArea, useVForm } from '../../../shared/forms'
import { Cancel, Save } from '@mui/icons-material'
import { resourseProtocolById } from '../../../shared/services/api'
import Cookie from 'js-cookie'

interface ModalRecursoProps {
  show: boolean
  onClose: () => void
  update: React.MutableRefObject<boolean>
  protocolId?: number | string
}

export const ModalRecurso: FC<ModalRecursoProps> = ({
  onClose,
  show,
  update,
  protocolId,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xsDown = useMediaQuery(theme.breakpoints.down(480))

  const { formRef } = useVForm()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    formRef.current?.reset()
    onClose()
  }

  const handleSubmit = async (formData: { justify_resource: string }) => {
    setIsLoading(true)
    await resourseProtocolById(
      Number(protocolId),
      formData.justify_resource,
      Cookie.get('external'),
    )
      .then((result) => {
        if (result instanceof Error) {
          NotificacaoError(result.message)
        } else {
          NotificacaoSucesso('Recurso aberto')
          setIsLoading(false)
          update.current = true
          onClose()
        }
      })
      .catch((err) => {
        NotificacaoError(err)
        setIsLoading(false)
      })
  }

  return (
    <Modal onClose={() => onClose()} open={show} aria-describedby="modal-title">
      <Box
        sx={FormBoxStyle}
        component={Paper}
        borderRadius="20px"
        overflow="auto"
        maxHeight="85vh"
        flexDirection="column"
        width={
          xsDown
            ? theme.spacing(40)
            : smDown
            ? theme.spacing(50)
            : mdDown
            ? theme.spacing(60)
            : lgDown
            ? theme.spacing(90)
            : theme.spacing(110)
        }
      >
        <ModalHeader
          showCloseButton
          title="Abrir recurso"
          onClose={handleClose}
        />

        <Divider />

        {isLoading && <LinearProgress sx={{ my: 2 }} />}

        <Box margin={1}>
          <VForm onSubmit={handleSubmit} ref={formRef}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <VTextArea
                  required
                  name="justify_resource"
                  minRows={4}
                  maxRows={8}
                  placeholder="Descreva aqui o motivo da abertura de recurso"
                  ariaLabel="Justificativa"
                  style={{
                    width: '95%',
                    paddingInline: '8px',
                    borderRadius: '10px',
                    fontVariant: 'historical-forms',
                    font: 'message-box',
                  }}
                />
              </Grid>
            </Grid>

            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
              mt={1}
              gap={1}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={<Save />}
                disableElevation
              >
                enviar
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<Cancel />}
              >
                cancelar
              </Button>
            </Box>
          </VForm>
        </Box>
      </Box>
    </Modal>
  )
}
